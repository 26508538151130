<div class="livebar container-fluid">
        <div class="scrolling-wrap" style="margin: 0 auto;">
            <ul class="comm">
                <li>
                    <img class="img-fluid" src="assets/images/home/network.webp" alt=""/>
                    <span class="d-flex flex-wrap align-items-center">Network:</span>
                    <select class="form-select select_network" [(ngModel)]="network_id" (ngModelChange)="selectNetwork($event)" name="network">
                        <ng-container *ngFor="let networks of network_list">
                            <option *ngFor="let network of networks.network" [value]="network.id">
                                {{network?.net_name}}
                            </option>
                        </ng-container>
                    </select>
                </li>
                <li>
                    <img class="img-fluid" src="assets/images/home/average-block.webp" alt=""/>
                    <span>Total Blocks: {{ network_scanner?.total_blocks | number}}</span>
                </li>
                <li>
                    <img class="img-fluid" src="assets/images/home/total-address.webp" alt=""/>
                    <span>Total Addresses: {{ network_scanner?.total_wallets | number}}</span>
                </li>
                <li>
                    <img class="img-fluid" src="assets/images/home/average-block-interval.webp" alt=""/>
                    <span>Average Blocks Intervals: {{ network_scanner?.block_interval}}</span>
                </li>
                <li>
                    <img class="img-fluid" src="assets/images/home/last-gas-fee.webp" alt=""/>
                    <span>Last Gas Fee: {{ network_scanner?.last_gas_fee }} </span>
                    <span>{{network_scanner?.token_name}}</span>
                </li>
            </ul>
            <ul class="comm comm-2">
                <li>
                    <img class="img-fluid" src="assets/images/home/network.webp" alt=""/>
                    <span class="d-flex flex-wrap align-items-center">Network:</span>
                    <select class="form-select select_network" [(ngModel)]="network_id" (ngModelChange)="selectNetwork($event)" name="network">
                        <ng-container *ngFor="let networks of network_list">
                            <option *ngFor="let network of networks.network" [value]="network.id">
                                {{network?.net_name}}
                            </option>
                        </ng-container>
                    </select>
                </li>
                <li>
                    <img class="img-fluid" src="assets/images/home/average-block.webp" alt=""/>
                    <span>Total Blocks: {{ network_scanner?.total_blocks | number}}</span>
                </li>
                <li>
                    <img class="img-fluid" src="assets/images/home/total-address.webp" alt=""/>
                    <span>Total Addresses: {{ network_scanner?.total_wallets | number}}</span>
                </li>
                <li>
                    <img class="img-fluid" src="assets/images/home/average-block-interval.webp" alt=""/>
                    <span>Average Blocks Intervals: {{ network_scanner?.block_interval}}</span>
                </li>
                <li>
                    <img class="img-fluid" src="assets/images/home/last-gas-fee.webp" alt=""/>
                    <span>Last Gas Fee: {{ network_scanner?.last_gas_fee }} </span>
                    <span>{{network_scanner?.token_name}}</span>
                </li>
            </ul>
          </div>
</div>