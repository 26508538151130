import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent {
  constructor(private modalService: NgbModal) {}

  open(content) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg', // You can set 'sm' for small, 'lg' for large, or specify custom width and height
      // Custom width and height example:
      // windowClass: 'custom-modal', // Add custom CSS class for further customization
      centered: true, // Center the modal vertically
      scrollable: true, // Allow modal body to scroll if content exceeds height
      // size: 'lg', // Set size: 'sm' for small, 'lg' for large, or specify custom width and height
      // backdrop: 'static', // Set to 'static' to prevent closing modal when clicking outside
      // keyboard: false, // Set to false to prevent closing modal with Esc key
      // windowClass: 'myCustomModalClass' // Add custom CSS class for further customization
    }).result.then((result) => {
      // Handle modal result
    }, (reason) => {
      // Handle modal dismissal reason
    });
  }
  
}
