import { Routes } from "@angular/router";

export const content: Routes = [
    {
        path: "home",
        loadChildren: () => import("../../components/homepage/home.module").then((m) => m.HomeModule),
    },
    {
        path: "blockscanner",
        loadChildren: () => import("../../components/blockscannerpage/blockscanner.module").then((m) => m.BlockScannerModule),
    },
    {
        path: "smartcontract",
        loadChildren: () => import("../../components/smartcontractpage/smart-contract.module").then((m) => m.SmartContractScannerModule),
    },
    // {
    //     path: "bootcamp",
    //     loadChildren: () => import("../../components/landing-page/landing-page.module").then((m) => m.LandingPageModule),
    // },
    {
        path: "policy",
        loadChildren: () => import('../../components/policy-page/policy-page.module').then((m) => m.PolicyPageModule),
    },
    {
        path: 'blogs',
        loadChildren: () =>
          import('../../components/blogpage/blog.module').then(
            (m) => m.BlogModule
          ),
    },
    {
        path: "xode-foundation",
        loadChildren: () => import('../../components/xode-foundation-page/xode-foundation.module').then((m) => m.XodeFoundationModule),
    },
];
