// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api_url_host: 'http://api.xode.net',
  chatbot_api_url : "http://chatbot-api.xgame.live",
  chatbot_api_ws_host : "chatbot-api.xgame.live",
  wss_provider_mainnet: 'wss://rpcnodea01.xode.net/n7yoxCmcIrCF6VziCcDmYTwL8R03a/rpc',
  wss_provider_testnet: 'wss://humidefi-dev.zeeve.net/para',
  keypair: localStorage.getItem("wallet-keypair") || "",
  WalletAPIURL: "https://wallet-api.xode.net",
  secret_key: "^a7T&kPzL#9s@4!gF%8H",
  BlogAPIURL: "https://blogs-api.xgame.live",
  google_play_store: "https://play.google.com/store/apps/details?id=com.xode.xodewallet&hl=en-PH",
  app_store: "https://apps.apple.com/ph/app/xode-wallet/id6479734235",
  wiki_xode: "https://wiki.xode.net/",
  networks: [
    {
      network: [
        {
          id: 1,
          name: 'Evolution',
          wsProviderEndpoint: 'wss://rpcnodea01.xode.net/n7yoxCmcIrCF6VziCcDmYTwL8R03a/rpc',
          net_name: 'Mainnet'
        }
      ]
    },
    {
      network: [
        {
          id: 2,
          name: 'Genesis',
          wsProviderEndpoint: 'wss://testrpcnodea01.xode.net/aRoyklGrhl9m2LlhX8NP/rpc',
          net_name: 'Testnet'
        }
      ]
    },
  ],
  domain_urls: [
    {
        url: "nft.xgame.live"
    },
    {
        url: "nft.xgame.co"
    },
    {
        url: "dashboard.xgame.live"
    },
    {
        url: "dashboard.xgame.co"
    },
    {
        url: "astrochibbi.xgame.live"
    },
    {
        url: "astrochibbi.xgame.co"
    },
    {
        url: "xgame.live"
    },
    {
        url: "xgame.co"
    },
    {
        url: "mint.xgame.live"
    },
    {
        url: "mint.xgame.co"
    },
    {
        url: "localhost"
    },
    {
      url: "xode.net"
    },
  ],
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
