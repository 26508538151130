import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CookiesService } from './../../../services/cookies.service';
import {WalletAccountsModel} from 'src/app/shared/model/polkadot.model';
import { PolkadotService } from 'src/app/shared/services/polkadot.service';

declare var require
const Swal = require('sweetalert2')
@Component({
  selector: 'app-wallet-list',
  templateUrl: './wallet-list.component.html',
  styleUrls: ['./wallet-list.component.scss']
})
export class WalletListComponent {

  constructor(
    private polkadotService: PolkadotService,
    public appSettings: AppSettings,
    private cookiesService: CookiesService,
  ){
  }

  loader: boolean = false;
  web3WalletArray: any[] = [];
  selectedWallet = "";
  walletAccounts: WalletAccountsModel[] = [];
  selectedWalletAccount: WalletAccountsModel = new WalletAccountsModel();
  isAccountChoosen: boolean = false;

  async getWeb3Extensions(){

    let dappExtension = await this.polkadotService.getDappExtension();
    let extensions = await dappExtension;
    setTimeout(() => {
      if (extensions.length != 0) {
        extensions.forEach(async data => {
          this.web3WalletArray.push({
            name: data.name,
            accounts: data.WalletAccounts
          });
        })
        }
    }, 100);
  }

  async selectWalletExtension(walletExtension: any) {
    let accounts = await walletExtension.accounts;

    this.walletAccounts = [];
    this.selectedWalletAccount = new WalletAccountsModel();

    if (accounts.length > 0) {
      for (let i = 0; i < accounts.length; i++) {
        this.walletAccounts.push({
          address: accounts[i].address,
          address_display: accounts[i].address.substring(0, 5) + "..." + accounts[i].address.substring(accounts[i].address.length - 5, accounts[i].address.length),
          metaGenesisHash: accounts[i].genesisHash,
          metaName: accounts[i].metaName,
          tokenSymbol: "",
          metaSource: walletExtension.name,
          type: accounts[i].type
        });
      }
    }
    
    this.selectedWallet = walletExtension.name;
    this.isAccountChoosen = true;
  }

  async onWalletSelectAndVerify(walletAccount: WalletAccountsModel) {

    let signAndVerify: Promise<boolean> = this.polkadotService.signAndVerify(walletAccount);
    let verified = (await signAndVerify);
    if (verified == true) {
      this.generateKeypair(walletAccount);
    }
  }

  async generateKeypair(walletAccount: WalletAccountsModel): Promise<void> {

    this.selectedWalletAccount = walletAccount;

    this.loader = true;
   
    let generateKeypair: Promise<string> = this.polkadotService.generateKeypair(this.selectedWalletAccount.address);
    let keypair = (await generateKeypair);
    if (keypair != "") {

      this.selectedWalletAccount.tokenSymbol = await this.polkadotService.getChainTokens();
      await this.cookiesService.setCookieArray("wallet-info",this.selectedWalletAccount);
      
      Swal.fire({
        icon: 'success',
        toast: true,
        // title: 'Connected',
        text: "Wallet Connected successfully",
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
        // timerProgressBar: true,
        willClose: () => {
          this.loader = false;
          window.location.href = '/smartcontract/create-xon20';
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          window.location.href = '/smartcontract/create-xon20';
        }
      })
    }
  }

  ngOnInit(): void {
    this.getWeb3Extensions();
  }
}
