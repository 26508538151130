<!-- <div class="section section-justify-center adjusted-margin-top with-background bootcamp-container">
    
    <div class="row">
        <div class="col-12 col-md-6 col-lg-6">
            <div class="iframe-container mt-2">
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSfjKkHgnIUa6bF--la--YqYq2frCkHh3jiht6VOB1WjyJX3lw/viewform?embedded=true&fbclid=IwAR2JGCMB4fMq9jPt86qbLo2xL_g8Q7sqS_QMrSfYtka-1-jaU5cmo3ARpXw"></iframe>
            </div>
        </div>
    </div>
</div> -->
<div class="container-fluid">
    <div class="col-xxl-9 col-xl-10 col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin: 0 auto;">
        <img src="../../../assets/home/Web-Banner-Xode-01.jpg" class="img-fluid mt-4">
        <div class="row">
            <div class="col-sm-12" style="text-align: center;">
                <h2 class="f-w-600 mt-2">Philippines' Web3 Bootcamp Schedule</h2>
                <button class="btn btn-info" (click)="open(content)" style="background-color: #E6087B !important;">Register Here!</button>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="card custom-card mt-4">
                    <div class="card-body">
                        <h5 class="f-w-600">Week 1</h5>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th><b>Date & Time</b></th>
                                        <th><b>Event</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>March 4, 2024 - Monday (10:00AM-5:00PM)</b></td>
                                        <td>Registration</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 4, 2024 - Monday (6:00PM-7:00PM)</b></td>
                                        <td>Pizza Party!</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 5, 2024 - Tuesday(10:00AM-11:00AM)</b></td>
                                        <td>Bingo Games</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 5, 2024 - Tuesday (11:00AM-12:00PM)</b></td>
                                        <td>Introduction to Web3</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 6, 2024 - Wednesday (10:00AM-11:00AM)</b></td>
                                        <td>Cryptocurrency Exchanges</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 6, 2024 - Wednesday (11:00AM-12:00PM)</b></td>
                                        <td>Staking</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 6, 2024 - Wednesday (1:00PM-3:00PM)</b></td>
                                        <td>Wallets and Security</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 6, 2024 - Wednesday (3:00PM-4:00PM)</b></td>
                                        <td>Xode Wallet</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 6, 2024 - Wednesday (4:00PM-5:00PM)</b></td>
                                        <td>XGame</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 7, 2024 - Thursday (10:00AM-12:00PM)</b></td>
                                        <td>NFTs (Non-Fungible Tokens)/Terminologies</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 8, 2024 - Friday (10:00PM-12:00PM)</b></td>
                                        <td>Activities ( Wallet Demo etc.)</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 9, 2024 - Saturday (10:00PM-12:00PM)</b></td>
                                        <td>Overview of Week 1</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="card custom-card mt-4">
                    <div class="card-body">
                        <h5 class="f-w-600">Week 2</h5>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th><b>Date & Time</b></th>
                                        <th><b>Event</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><b>March 11, 2024 - Monday (10:00AM-12:00PM)</b></td>
                                        <td>Cryptography 1 - Introduction to Cryptography/Addresses and Keys</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 11, 2024 - Monday (2:00PM-5:00PM)</b></td>
                                        <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>March 12, 2024 - Tuesday(1:00PM-2:00PM)</b></td>
                                        <td>Cryptography 2 -Hash Functionse</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 12, 2024 - Tuesday (2:00PM-5:00PM)</b></td>
                                        <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>March 13, 2024 - Wednesday (10:00AM-12:00PM)</b></td>
                                        <td>Cryptography 3 - Encryption</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 13, 2024 - Wednesday (2:00PM-5:00PM)</b></td>
                                        <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>March 14, 2024 - Thursday (10:00PM-12:00PM)</b></td>
                                        <td>ryptography 5- Hash Base Data Structures (Merkle Tree)</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 14, 2024 - Thursday (2:00PM-5:00PM)</b></td>
                                        <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>March 15, 2024 - Friday (10:00AM-12:00PM)</b></td>
                                        <td>Cryptography 4- Digital Signature (From Basic to Advance)</td>
                                    </tr>
                                    <tr>
                                        <td><b>March 15, 2024 - Friday (2:00PM-5:00PM)</b></td>
                                        <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>March 16, 2024 - Saturday (10:00PM-12:00PM)</b></td>
                                        <td>Overview of Week 2</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="card custom-card mt-4">
                    <div class="card-body">
                        <h5 class="f-w-600">Week 3</h5>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th><b>Date & Time</b></th>
                                    <th><b>Event</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>March 18, 2024 - Monday (10:00AM-12:00PM)</b></td>
                                    <td>Overview of Blockchain</td>
                                </tr>
                                <tr>
                                    <td><b>March 18, 2024 - Monday (2:00PM-5:00PM)</b></td>
                                    <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                </tr>
                                <tr>
                                    <td><b>March 19, 2024 - Tuesday(1:00PM-2:00PM)</b></td>
                                    <td>Blockchain Structure</td>
                                </tr>
                                <tr>
                                    <td><b>March 19, 2024 - Tuesday (2:00PM-5:00PM)</b></td>
                                    <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                </tr>
                                <tr>
                                    <td><b>March 20, 2024 - Wednesday (10:00AM-12:00PM)</b></td>
                                    <td>Peer-to-Peer Networking</td>
                                </tr>
                                <tr>
                                    <td><b>March 20, 2024 - Wednesday (2:00PM-5:00PM)</b></td>
                                    <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                </tr>
                                <tr>
                                    <td><b>March 21, 2024 - Thursday (10:00PM-12:00PM)</b></td>
                                    <td>Understanding Consensus Mechanisms in Polkadot</td>
                                </tr>
                                <tr>
                                    <td><b>March 21, 2024 - Thursday (2:00PM-5:00PM)</b></td>
                                    <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                </tr>
                                <tr>
                                    <td><b>March 22, 2024 - Friday (10:00AM-12:00PM)</b></td>
                                    <td>Accounting Models & User Abstractions in Blockchains</td>
                                </tr>
                                <tr>
                                    <td><b>March 22, 2024 - Friday (2:00PM-5:00PM)</b></td>
                                    <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                </tr>
                                <tr>
                                    <td><b>March 23, 2024 - Saturday (10:00PM-12:00PM)</b></td>
                                    <td>Overview of Week 3</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="card custom-card mt-4">
                    <div class="card-body">
                        <h5 class="f-w-600">Week 4</h5>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th><b>Date & Time</b></th>
                                    <th><b>Event</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>March 25, 2024 - Monday (10:00AM-11:00AM)</b></td>
                                    <td>Smart Contract - Our Hello Word</td>
                                </tr>
                                <tr>
                                    <td><b>March 25, 2024 - Monday (11:00AM-12:00PM)</b></td>
                                    <td>ERC 721-1155</td>
                                </tr>
                                <tr>
                                    <td><b>March 25, 2024 - Monday (1:00PM-2:00PM)</b></td>
                                    <td>Upgradable Contract</td>
                                </tr>
                                <tr>
                                    <td><b>March 25, 2024 - Monday (2:00PM-5:00PM)</b></td>
                                    <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                </tr>
                                <tr>
                                    <td><b>March 26, 2024 - Tuesday (10:00AM-11:00AM)</b></td>
                                    <td>A multi signature wallet</td>
                                </tr>
                                <tr>
                                    <td><b>March 26, 2024 - Tuesday (11:00AM-12:00PM)</b></td>
                                    <td>Allow runtime access</td>
                                </tr>
                                <tr>
                                    <td><b>March 26, 2024 - Tuesday (1:00PM-2:00PM)</b></td>
                                    <td>Cross-contact calls</td>
                                </tr>
                                <tr>
                                    <td><b>March 26, 2024 - Tuesday (2:00PM-5:00PM)</b></td>
                                    <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                </tr>
                                <tr>
                                    <td><b>March 27, 2024 - Wednesday (10:00AM-11:00AM)</b></td>
                                    <td>Implement ERC 20 traits</td>
                                </tr>
                                <tr>
                                    <td><b>March 27, 2024 - Wednesday (11:00AM-12:00PM)</b></td>
                                    <td>Simple Domain Name Server</td>
                                </tr>
                                <tr>
                                    <td><b>March 27, 2024 - Wednesday (1:00PM-4:00PM)</b></td>
                                    <td style="color: rgb(233, 81, 35);"><b>Coding Activities</b></td>
                                </tr>
                                <tr>
                                    <td><b>March 27, 2024 - Wednesday (4:00PM-5:00PM)</b></td>
                                    <td>Closing of Web3 Bootcamp</td>
                                </tr>
                                <tr>
                                    <td><b>March 27, 2024 - Wednesday (5:00PM-6:30PM)</b></td>
                                    <td>Giving of Certificates</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Registration Form</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <iframe style="width: 100%; height: 100%;"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfjKkHgnIUa6bF--la--YqYq2frCkHh3jiht6VOB1WjyJX3lw/viewform?embedded=true&fbclid=IwAR2JGCMB4fMq9jPt86qbLo2xL_g8Q7sqS_QMrSfYtka-1-jaU5cmo3ARpXw"></iframe>
    </div>
  </ng-template>