<!-- page-wrapper Start-->
<div [ngClass]="layout.config.settings.layout_type">
  <div class="page-wrapper" id="canvas-bookmark" [ngClass]="layoutClass">
    <app-header></app-header>
    <!-- <app-livebar></app-livebar> -->
    <!-- Page Body Start-->
    
    <div class="page-body-wrapper">
      <div class="page-body">
        <main >
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
        <app-footer></app-footer>
    </div>
  </div>
</div>
<div class="bg-overlay1"></div>
<app-customizer></app-customizer>
<!-- page-wrapper End-->
