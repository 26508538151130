import { Injectable } from '@angular/core';
import { DappExtensionModel, WalletAccountsModel } from 'src/app/shared/model/polkadot.model';
import { web3Accounts, web3Enable, web3FromAddress, web3FromSource } from '@polkadot/extension-dapp';
import { cryptoWaitReady, decodeAddress, signatureVerify } from '@polkadot/util-crypto';
import { hexToU8a, isHex, stringToHex, stringToU8a, u8aToHex } from '@polkadot/util';
import { Keyring, encodeAddress } from '@polkadot/keyring';
import { ApiPromise } from '@polkadot/api';
import { WsProvider } from '@polkadot/rpc-provider';
import { CookiesService } from './../services/cookies.service';
import { formatBalance, BN } from '@polkadot/util';
import { ContractPromise, CodePromise } from '@polkadot/api-contract';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NetworkScannerModel } from '../model/networkscanner.model';
import { SmartContractService } from './smartcontract.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PolkadotService {

  public blockSubscription: any;
  private currentBalance: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private currentToken: BehaviorSubject<string> = new BehaviorSubject<any>('XON');
  private networkScanner: BehaviorSubject<NetworkScannerModel> = new BehaviorSubject<any>(NetworkScannerModel);
  private tokenMetrics: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tokenMetrics$ = this.tokenMetrics.asObservable();

  wsProvider = new WsProvider(this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').wsProviderEndpoint  :environment.networks[0].network[0].wsProviderEndpoint);
  
  contractAddress;
  api: any;
  keypair = environment.keypair;
  extensions;
  accounts;
 
  constructor(
    private cookiesService: CookiesService,
    private smartcontractService: SmartContractService,
    private http: HttpClient
  ) {
    this.connect();
  }

  async connect() {
    const provider = new WsProvider(this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').wsProviderEndpoint  :environment.networks[1].network[0].wsProviderEndpoint);
    this.contractAddress = this.cookiesService.getCookie('contract_address');
    this.api = ApiPromise.create({ provider });
    // this.extensions = await web3Enable('Xode');
  }

  async disconnect(){
    this.wsProvider.disconnect();
  }

  async getDappExtension(): Promise<DappExtensionModel[]> {
    let web3WalletArray: DappExtensionModel[] = [];
    let extensions = await web3Enable('Xode');

    if (extensions.length != 0) {
      await extensions.forEach(async data => {

        let walletAccounts: WalletAccountsModel[] = [];

        let accounts = await data.accounts.get();
        
        accounts.forEach(account => {
          walletAccounts.push({
            address: account.address,
            address_display: account.address.substring(0, 5) + "..." + account.address.substring(account.address.length - 5, account.address.length),
            metaGenesisHash: account.genesisHash,
            metaName: account.name,
            tokenSymbol: "",
            metaSource: data.name,
            type: account.type
          });
        });
        
        web3WalletArray.push({
          name: data.name,
          WalletAccounts: walletAccounts
        });
      })
      console.log(web3WalletArray)
      return web3WalletArray;
    }

    return [];
  }

  async getWeb3Accounts(): Promise<any[]> {
    let walletAccounts: WalletAccountsModel[] = [];

    if ((await this.extensions).length > 0) {
      const accounts = await this.accounts;
      if (accounts.length > 0) {
        for (let i = 0; i < accounts.length; i++) {
          walletAccounts.push({
            address: accounts[i].address,
            address_display:  accounts[i].address.substring(0, 5) + "..." + accounts[i].address.substring(accounts[i].address.length - 5, accounts[i].address.length),
            metaGenesisHash: accounts[i].meta.genesisHash,
            metaName: accounts[i].meta.name,
            tokenSymbol: "",
            metaSource: accounts[i].meta.source,
            type: accounts[i].type
          });
        }
      }
      this.getChainTokens();
    }

    return walletAccounts;
  }

  async getAccount(contractAddress: any) {
    try {
      let accounts = [];

      do {
        await web3Enable('XGAME DASHBOARD');
        accounts = await web3Accounts();
        if (accounts.length === 0) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } while (accounts.length === 0);
     
      const SENDER = this.cookiesService.getCookieArray("wallet-info").address;
      const injector = await web3FromAddress(SENDER);
      let api = await this.api;

      // Returns the data
      return { api, SENDER, injector };
    } catch (error) {
      console.error('Get account error: ' + error);
      return undefined;
    }
  }

  async getBalance() {
    try {
      const contractAddress = await this.getAllSmartContracts();
      const accountData = await this.getAccount(contractAddress);

      if (accountData && accountData.api) {
        const { api, SENDER } = accountData;
        const accountInfo: any = await api.query.system.account(SENDER);
        const { nonce, data: balance } = accountInfo.toJSON();
        const chainDecimals = api.registry.chainDecimals[0];
        formatBalance.setDefaults({ decimals: chainDecimals, unit: 'NMS' });
        formatBalance.getDefaults();
        const free = formatBalance(balance.free, { forceUnit: "NMS", withUnit: false });
        const balances = free.split(',').join('');
        this.setCurrentBalance(balances);
        // return balances;
      } else {
        console.error('API not available in the returned data.');
        return undefined;
      }
    } catch (error) {
      console.error('Get account balance error:', error);
      return undefined;
    }
  }

  async signAndVerify(walletAccount: WalletAccountsModel): Promise<boolean> {
    const injector = await web3FromSource(String(walletAccount.metaSource));
    const signRaw = injector?.signer?.signRaw;

    if (!!signRaw) {
      await cryptoWaitReady();

      const message: string = 'Please sign before you proceed. Thank you!';
      const { signature } = await signRaw({
        address: walletAccount.address,
        data: stringToHex(message),
        type: 'bytes'
      });

      let publicKey = decodeAddress(walletAccount.address);
      let hexPublicKey = u8aToHex(publicKey);

      let { isValid } = signatureVerify(message, signature, hexPublicKey);
      return isValid;
    }

    return false;
  }

  async generateKeypair(address: string): Promise<string> {
    const keyring = new Keyring({ type: 'sr25519', ss58Format: 0 });
    const hexPair = keyring.addFromAddress(address);

    return hexPair.address;
  }

  isAddressValid(walletAddress: string) {
    try {
      encodeAddress(
        isHex(walletAddress)
          ? hexToU8a(walletAddress)
          : decodeAddress(walletAddress)
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getChainDecimals(amount: number) {
    let api = await this.api;
    const factor = new BN(10).pow(new BN(api.registry.chainDecimals));
    const convertedAmount = new BN(amount).mul(factor);
    return convertedAmount;
  }

  async checkBalance(wallet_address) {
    let api = await this.api;
    const balance = await api.derive.balances.all(wallet_address);
    const available = balance.availableBalance;
    const chainDecimals = api.registry.chainDecimals[0];
    formatBalance.setDefaults({ decimals: chainDecimals, unit: 'NMS' });
    formatBalance.getDefaults();
    const free = formatBalance(available, { forceUnit: "NMS", withUnit: false });
    const balances = free.split(',').join('');
    return parseFloat(balances) < 100 ? true : false;
  }

  async getChainTokens(): Promise<string> {
   
    const api = await this.api;
    const tokens = await api.registry.chainTokens;
    this.cookiesService.setCookie('tokenSymbol', tokens[0]);
    return tokens[0];
  }

  async transferBalanceReady(data: any) {
    let api = await this.api;
    let hash = data;
    const hashresult = await api.rpc.author.submitExtrinsic(hash);
  }

  async getBuyNftFees(recepient: string, nft: any) {
    let api = await this.api;
    let wallet = this.cookiesService.getCookie('wallet-keypair');
    const factor = new BN(10).pow(new BN(api.registry.chainDecimals));
    const amount = new BN(nft.price).mul(factor);
    const transfer = api.tx.balances.transfer(recepient, amount); // to get payment info
    const { partialFee } = await transfer.paymentInfo(wallet);
    const chainDecimals = api.registry.chainDecimals[0];
    formatBalance.setDefaults({ decimals: chainDecimals, unit: 'GNT' });
    formatBalance.getDefaults();

    // Extracting BN from Codec
    const fees = (partialFee as any).toBn().muln(110).divn(100).add(api.consts.balances.existentialDeposit);

    const free = formatBalance(fees, { forceUnit: "GNT", withUnit: false });
    const balances = free.split(',').join('');
    return balances;
  }

  public async setTotalBlocks(): Promise<void> {
    let network: NetworkScannerModel = new NetworkScannerModel();
    
    const api = await this.api;

    // Get Total Wallet
    const totalWallet: any = await api.query.system.account.entries();
    network.total_wallets = totalWallet.length

    // Get Net Name
    network.net_name = this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').net_name  : environment.networks[0].network[0].net_name

    // Get Token Name
    const tokens = await api.registry.chainTokens;
    network.token_name = tokens[0]
    this.currentToken.next(tokens[0])

    this.blockSubscription = api.derive.chain.subscribeNewHeads(async (lastHeader: any) => {
      const events: any = await api.query.system.events.at(lastHeader.hash);
      events.forEach(async ({ event }) => {
       
          const properties = await api.rpc.system.properties();
          const data: any = properties.toHuman();
          const symbol = data.tokenSymbol[0];
          const decimal = data.tokenDecimals[0];
          formatBalance.setDefaults({ decimals: decimal, unit: symbol });
          formatBalance.getDefaults();
          const bal = formatBalance(
            event.data.actualFee,
            {
              forceUnit: symbol,
              withUnit: false
            }
          );
          const balances = parseFloat(bal).toFixed(6);
          const fee_format = `${balances}`;
          network.last_gas_fee = parseFloat(fee_format) == 0.000000 ? 0.00212 : parseFloat(fee_format);
      });
  
      network.total_blocks = lastHeader.number.toNumber();
      this.networkScanner.next(network)
    });
  }

  async getAllSmartContracts() {
    let api = await this.api;
    const allContracts = await api.query.contracts.contractInfoOf.entries();

    // Extract contract addresses from the result
    const contractAddresses = allContracts.map(([key, _]) => JSON.stringify(key.args[0].toJSON()));

    // Check if the contractAddresses array is not empty before setting the cookie
    if (contractAddresses.length > 0) {
      this.cookiesService.setCookie('smart_contract', contractAddresses[0]);
    }
  }

  async getContract(api: any, abi: any, contractAddress: any) {
    try {
      const contract = new ContractPromise(api, abi, contractAddress);
      return contract;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getErc20(endpoint: string): Promise<any> {
    const data = await this.smartcontractService.getSmartContract(endpoint).toPromise();
    return data[1];
  }

  async deploySmartContract(
    data: any,
    endpoint: string,
  ): Promise<any> {
    const api: any = await ApiPromise.create({provider: this.wsProvider});
    try {

      const storageDepositLimit = null;

      let abiWasm: any = await this.getErc20(endpoint);
      let contract_Wasm = JSON.parse(abiWasm.contract);
      let actingAddress = this.cookiesService.getCookieArray("wallet-info").address;
      let gasLimit = api?.registry.createType(
        'WeightV2',
        { refTime: 892707453,
          proofSize: 24530,
        },
      )
      const contract = new CodePromise(api, abiWasm.abi, contract_Wasm.source.wasm);
      const injector = await web3FromAddress(actingAddress);
      let tx: any;
      if (typeof data === 'object') {
        tx = contract.tx.new(
          { gasLimit, storageDepositLimit },
          data.tokenName,
          data.tokenSymbol,
          data.decimals,
          data.tokenOwner,
          data.totalSupply,
          data.mintTo,
          data.mintable,
          data.burnable,
          data.ownable,
        );
      } else if (typeof data === 'string') {
        tx = contract.tx.new(
          { gasLimit, storageDepositLimit },
          data
        );
      } else {
        throw new Error('Unsupported data type for deployment');
      }
      return await new Promise(async (resolve, reject) => {
        try {
          await tx.signAndSend(
            actingAddress,
            { 
              signer: injector.signer, 
              nonce: -1
            },
            async (result: any) => {
             
              if (result.dispatchError) {
                if (result.dispatchError.isModule) {
                  const decoded = api.registry.findMetaError(result.dispatchError.asModule);
                  const { docs, name, section } = decoded;
                  console.log(`${section}.${name}: ${docs.join(' ')}`);
                  this.smartcontractService.fireSwal(
                    false,
                    'Transaction failed!',
                    `${section}.${name}: ${docs.join(' ')}`
                  );
                } else {
                  console.log(result.dispatchError.toString());
                }
                const error = {
                  contract_address: '',
                  status: true
                };
                reject(error);
              } else if (result.status.isBroadcast) {

                this.smartcontractService.progressSwal(
                  'Uploading...'
                );
              } else if (result.status.isInBlock) {

                this.smartcontractService.closeSwal();
                this.smartcontractService.fireSwal(
                  result.status.isInBlock,
                  'Smart contract creation success!',
                  'The transaction was successful'
                );
                this.downloadJson(contract_Wasm, `${result.contract.address}.json`);
                resolve({
                  contract_address: result.contract.address.toString(),
                  status: true
                });
              }
            }
          );
        } catch (error) {
          this.smartcontractService.fireSwal(
            false,
            'Error occured!',
            error
          );
          const result = {
            contract_address: '',
            status: true
          };
          reject(result);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  downloadJson(data: any, filename: string) {
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  setCurrentBalance(data: any) {
    this.currentBalance.next(data);
  }

  getCurrentToken(){
    return this.currentToken.asObservable();
  }

  getCurrentBalance() {
    return this.currentBalance.asObservable();
  }

  getTotalBlocks(){
    return this.networkScanner.asObservable();
  }

  setTokenMetrics(): Observable<any> {
    const tokenMetrics = this.http.get<any>(environment.WalletAPIURL+'/chain/supply');
    this.tokenMetrics.next(tokenMetrics);
    return tokenMetrics;
  }

  getTokenMetrics() {
    return this.tokenMetrics.value;
  }
}
