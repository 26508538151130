import { Component, OnInit } from '@angular/core';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { BlockScannerService } from '../../services/blockscanner.service';
import { formatBalance } from '@polkadot/util';
import { CookiesService } from '../../services/cookies.service';
import { environment } from 'src/environments/environment';
import { PolkadotService } from '../../services/polkadot.service';
import {NetworkScannerModel} from 'src/app/shared/model/networkscanner.model'
import { NetworkModel } from '../../model/network.model';
@Component({
  selector: 'app-livebar',
  templateUrl: './livebar.component.html',
  styleUrls: ['./livebar.component.scss']
})
export class LivebarComponent {
  constructor(
    //
    public blockScannerService: BlockScannerService,
    private cookiesService: CookiesService,
    private polkadotService: PolkadotService
  ) {
    environment.networks.forEach(networkGroup => {
      networkGroup.network.forEach(network => {
        this.networks.push({
          id: network.id,
          name: network.name,
          wsProviderEndpoint: network.wsProviderEndpoint,
          net_name: network.net_name
        })
      });
    });
    if(this.cookiesService.getCookieArray('network')==null){
      this.cookiesService.setCookieArray('network',this.networks[0])
    }
  }

  networks: NetworkModel[] = [];
  

  public blockSubscription: any;
  network_scanner: NetworkScannerModel = new NetworkScannerModel()

  public show: boolean = false

  network_list: any = environment.networks;
  selectedNetwork:any = this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network')  :environment.networks[0].network;
  network_id: number = this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').id  :environment.networks[0].network[0]?.id;

  isMoreLangActive: boolean = false;

  toggleMoreLang() {
    this.isMoreLangActive = !this.isMoreLangActive;
  }

  toggle() {
    this.show = !this.show
  }

  async ngOnInit(): Promise<void> {
    this.polkadotService.setTotalBlocks();
    this.polkadotService.getTotalBlocks().subscribe((data)=>{
      if(data.net_name!=undefined){
        this.network_scanner = data;
      }
    })
    // this.getTotalWallet();
  }

  selectNetwork(network_id: any): void {
    const network = this.findNetworkById(network_id);
    if (network) {
        this.selectedNetwork = network;
        // Uncomment below lines if needed
        this.cookiesService.setCookieArray('network', network);
        window.location.reload();
    } else {
        console.error('Network not found with id:', network_id);
    }
    // this.cookiesService.setCookieArray('network',network);
    // window.location.reload();
  }
  findNetworkById(network_id: any) {
    for (const networkObj of environment.networks) {
      const networkObject = networkObj.network[0]
        const network = networkObject.id==network_id
        if (network) {
            return networkObject;
        }
    }
    return null;
}
}
