
<div style="display: flex; justify-content: center;" class="p-b-40">
    <img src="./../../../../../assets/logos/xode-logo-black-pink.webp" alt="" class="img-fluid for-light" style="height: 50px;">
    <img src="./../../../../../assets/logos/xode-logo-black-pink.webp" alt="" class="img-fluid for-dark">
</div>
<div *ngIf="selectedWallet == ''">
    <div class="connect-wallet-title text-center" style="margin-bottom: 40px;">
        <div class="text-center f-18">{{'Connect Wallet' | translate}}</div>
        <span class="text-center text-sm w-full">{{'Start using Xode' | translate}}</span>
    </div>
    <div class="connect-wallet-button" style="margin-bottom: 40px;">
        <ul>
            <li *ngFor="let item of web3WalletArray">
            <button class="btn btn-wallet" type="button" style="padding: 0.9375rem 1.5625rem; width: 100%; display: flex; align-items: center; justify-content: space-between; margin: 5px;" (click)="selectWalletExtension(item)">
                <div style="display: flex; gap: 0.5rem;">
                    <img alt="logo" *ngIf="item == 'polkadot-js'" src="./../../../../../assets/polkadot/polkadot/polkadot_icon.webp" style="width: 1.5rem" />
                    <span class="f-20">{{item.name}}</span>
                </div>
                <i class="icon-arrow-right" style="font-size: 1rem;"></i>
            </button>
        </li>
        </ul>
    </div>
</div>



<div style="padding: 10px;" *ngIf="selectedWallet != ''">
    <div *ngIf="isAccountChoosen == true">
        <div class="connect-wallet-title">
            <div class="text-center" style="font-size: 20px; margin-bottom: 5px;">
                <span>
                    {{'Choose Account' | translate}}
                </span>
            </div>
        </div>
        <br />
        <div class="row account-list" *ngIf="loader!=true">
            <div class="col-xl-12 p-10 account-div" *ngFor="let web3Wallet of walletAccounts" (click)="onWalletSelectAndVerify(web3Wallet)" style="min-width: 100%;">
                <span class="disable-hover" style="font-size: 20px; font-weight: bold;">
                    {{web3Wallet.metaName}}
                </span>
                <br />
                <span class="disable-hover"  style="font-size: 12px;">
                    {{web3Wallet.address}}
                </span>
          </div>
        </div>
        <div class="loader-box" *ngIf="loader">
            <div class="loader-30"></div>
        </div>
    </div>
    <br />
    <div style="text-align: center; width: 100%;display: flex;
    justify-content: center;">
        <button class="btn btn-pill btn-back" (click)="selectedWallet = ''"><i class="icon-arrow-left" style="font-size: 1rem;"></i></button>
    </div>
    <br />
</div>

<div class="text-sm text-center">
    <span>{{'By connecting, I accept Xode’s Terms of Service' | translate}}</span>
</div>
