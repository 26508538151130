<div class="customizer-links" [ngClass]="{ open: customizer != '' }" style="z-index: 6;">
  <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
    <a href="javascript:void(0)" class="nav-link" [ngClass]="{ 'active show': customizer == 'layout' }" (click)="Customizer('ChatBot')">
      <div class="settings">
        <div class="settings"><i class="icofont icofont-chat"></i></div>
      </div>
      <span>How can we help you?</span>
    </a>
    <!-- <a href="javascript:void(0)" class="nav-link" [ngClass]="{ 'active show': customizer == 'option' }" id="c-pills-home-tab" (click)="Customizer('option')">
      <div class="settings"><i class="icon-settings"></i></div>
      <span>Cart</span>
    </a> -->
  </div>
</div>
<div class="customizer-contain" [ngClass]="{ open: customizer != '' }">
  <div class="tab-content" id="c-pills-tabContent">
    <div class="customizer-header">
      <i class="icofont-close icon-close" (click)="Customizer('')"></i>
      <h5 style="text-transform:unset">Chat With Us!</h5>
    </div>
    <div class="customizer-body custom-scrollbar" style="max-height: calc(95vh - 150px);">
      <div class="tab-pane fade" [ngClass]="{ 'active show': customizer == 'ChatBot' }" id="c-pills-layouts" role="tabpanel" style="display: flex; gap:1rem;flex-direction: column;" *ngFor="let chat of chatBotModel">
        <div class="sender m-b-20" *ngIf="chat.reply!=false">
          <div class="" style="    text-align: right;">
            {{chat.date}}
          </div>
          <div class="" style="    text-align: right;
          background-color: #EEEFF1;
          padding: 10px;
          border-radius: 10px;
          margin-left: auto;
          width: 75%;">
            {{chat.text}}
          </div>
        </div>
        <div class="receiver" *ngIf="chat.reply!=true&&chat.text!=''">
          <div>
            {{chat.date}}
          </div>
          <div class="anim-typewriter" style="background: #2D2D2D;         padding: 10px;
          border-radius: 10px;
          margin-right: auto;
          width: 80%;">
            {{chat.text}}
          </div>
        </div>

      </div>
      <!-- <div class="tab-pane fade" [ngClass]="{ 'active show': customizer == 'option' }" id="c-pills-home" role="tabpanel" aria-labelledby="c-pills-home-tab">
        <h6>Shopping Cart</h6>
      </div> -->
    </div>
    <div class="customizer-footer">
      <div class="mb-3">
        <form #chatForm="ngForm" (ngSubmit)="chat_bot_send()">
          <div class="input-group">
            <input 
              class="form-control" 
              style="color: black !important;" 
              type="text" 
              placeholder="Recipient's username" 
              [(ngModel)]="chat_text" 
              aria-label="Recipient's username"
              [disabled]="loading" 
            />            
            <button class="btn btn-light" [disabled]="loading">
              <i class="fa fa-send-o" *ngIf="!loading"></i>
              <i class="fa fa-spin fa-spinner me-2" *ngIf="loading"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
