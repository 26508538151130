import { Component, OnInit,Input, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettings } from "src/app/app-settings";
import { WalletModel } from "src/app/shared/model/wallet.model";
import { CookiesService } from "src/app/shared/services/cookies.service";
import { PolkadotService } from "src/app/shared/services/polkadot.service";
import { ClipboardService } from 'ngx-clipboard';
const Swal = require('sweetalert2')

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  @Input() wallet: WalletModel;
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  tokenSymbol: string = 'XON';
  public wallet_info: any = this.cookiesService.getCookieArray("wallet-info");
  public wallet_balance: any = "0.00";
  isRendered: boolean = false;

  @ViewChild('waInputField') waInputField: ElementRef; 

  constructor(
    public router: Router,
    private cookiesService: CookiesService,
    public appSettings:AppSettings,
    private polkadotService: PolkadotService,
    private clipboardService: ClipboardService
    ) {
    this.tokenSymbol = this.cookiesService.getCookieArray('wallet-info').tokenSymbol;
  }
  public wallet_address = "";
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
  copyToClipboard(): void {
    this.clipboardService.copyFromContent(this.wallet_info.address);
  }
  ngOnInit() {
    // Subscribe to the BehaviorSubject to get the latest data
    this.polkadotService.getBalance();
    this.polkadotService.getCurrentBalance().subscribe(data => {
      if(data != null)
        this.wallet_balance = data;
    });
    this.polkadotService.getCurrentToken().subscribe(data => {
      this.tokenSymbol = data;
    });

    this.wallet_address = this.wallet_info.address;
    setTimeout(() => {
      this.isRendered = true;
    }, 1000);
  }

  logout(){
    this.polkadotService.disconnect();
    const logout = this.cookiesService.deleteAllCookie();
    if (logout){
      Swal.fire({
        icon: 'success',
        toast: true,
        // title: 'Connected',
        text: "Wallet disconnected successfully",
        position: 'top-end',
        timer: 1500,
        showConfirmButton: false,
        // timerProgressBar: true,
        willClose: () => {
          window.location.reload();

        }
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          window.location.reload();
        }
      })

    }
  }
}
