<swiper [direction]="'vertical'" [loop]="true" [autoHeight]="true" [allowTouchMove]="false" [autoplay]="{ delay: 1000, disableOnInteraction: false }" [pagination]="false" [navigation]="false">
  <!-- <ng-template data-swiper-autoplay="2500" swiperSlide>
    <div class="d-flex h-100">
      <img src="assets/images/giftools.gif" alt="gif" />
      <h6 class="mb-0 f-w-400"><span class="font-primary">Don't Miss Out! </span><span class="f-light">Out new update has been release.</span></h6>
      <i class="icon-arrow-top-right f-light"></i>
    </div>
  </ng-template> -->
  <ng-template data-swiper-autoplay="5000" swiperSlide>
    <div class="d-flex h-100 container">
      
      <h6 class="mb-0 f-w-400"><span class="f-light" style="color: black;"></span>Join the Philippines' Web3 Bootcamp. It's free!</h6>
      <!-- <img src="assets/images/giftools.gif" alt="gif" /> -->
      <a href="javascript:void(0)" class="ms-1" style="color: #E6087B;"
      routerLink="/bootcamp"><b>Register now !</b></a>
    </div>
  </ng-template>

  <!-- <div class="container">
    <ng-template data-swiper-autoplay="5000" swiperSlide>
      <div class="center-content">
        <h6 class="mb-0 f-w-400"><span class="f-light">Apply to join this bootcamp </span></h6>
        <a class="ms-1" href="https://1.envato.market/3GVzd" target="_blank">Register now!</a>
      </div>
    </ng-template>
  </div> -->
  
</swiper>
