<!-- Page Header Start-->
<div class="page-header theme-bg-blue" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0 theme-bg-blue justify-content-between container-xxl" style="margin: 0 auto !important;">
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper d-flex">
        <a routerLink="/">
          <img class="img-fluid for-light" src="assets/logos/x-code-logo-pink.webp" alt=""  style="height: 50px;"/>
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" />
        </a>
      </div>
    </div>
    <!-- <div class="left-header col-xxl-5 col-xl-6 col-lg-5 col-md-4 col-sm-3 p-0">
      <app-swiper></app-swiper>
    </div> -->

    <ul class="nav nav-pills card-header-pills sm-ml-6 sm-block " style="width: fit-content;">
      <ng-container *ngFor="let menuItem of menuItems">
        <li class="nav-item me-1" *ngIf="menuItem.type=='link'">
          <a class="nav-link text-white f-16" (click)="routeClick(menuItem.title)" [routerLink]="!menuItem.type ? null : [menuItem.path]">{{menuItem.title}}</a>
        </li>
        <li class="nav-item me-1" *ngIf="menuItem.type=='hyperlink'" style="text-align: left;">
          <a class="nav-link text-white  f-16" (click)="routeClick(menuItem.title)" [href]="[menuItem.path]" target="_blank">{{menuItem.title}}</a>
        </li>
        <li class="nav-item dropdown" *ngIf="menuItem.type=='sub'">
          <a href="javascript:void(0)" class="nav-link dropdown-toggle text-white f-16" (click)="toggleDropdown($event, menuItem)" role="button" aria-haspopup="true" [attr.aria-expanded]="currentDropdown === menuItem ? 'true' : 'false'"
            style="background-color: unset; width: 100%; padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);">{{menuItem.title}}</a>
          <ul class="dropdown-menu" [ngClass]="{'show': currentDropdown === menuItem}">
            <li *ngFor="let childrenItem of menuItem.children">
              <a class="dropdown-item f-16" [routerLink]="!childrenItem.type ? null : [childrenItem.path]" routerLinkActive="router-link-active" (click)="onSubItemClick($event, menuItem)">{{childrenItem.title}}</a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>

    <div class="nav-right col-8 p-0" style="width: fit-content;">
      <ul class="nav-menus">
        <li class="language-nav" (click)="languageToggle()">
          <app-languages></app-languages>
        </li>
        <li *ngIf="wallet == null" style="padding: 0px 0px 0px 6px;">
          <button class="btn btn-primary btn-pill btn-lg d-flex align-items-center" (click)="openConnectWallet(content)" *ngIf="wallet ==null">
            <i class="icofont icofont-wallet f-26 me-1"></i> 
            <span class="f-14 f-w-500">Connect Wallet </span>
          </button>
        </li>
        <li class="profile-nav onhover-dropdown p-0" *ngIf="wallet !=null">
          <app-my-account [wallet]="wallet" *ngIf="isRendered"></app-my-account>
        </li>
      </ul>
    </div>
    <a type="button" class="btn btn-collapse text-white" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample" style="width: min-content; border: unset !important;">
      <i class="icofont icofont-justify-all f-24"></i>
    </a>
  </div>
</div>
<!-- Page Header Ends -->

<div class="sticky-top">
  <div
  id="collapseExample" [ngbCollapse]="!isCollapsed">
   <div class="card mb-0" style="border-radius: unset !important;">
     <div class="card-body p-0">
       <ul class="nav flex-column">
         <ng-container *ngFor="let menuItem of menuItems">
           <li class="nav-item me-1" *ngIf="menuItem.type=='link'" style="text-align: left;">
             <a class="nav-link text-black  f-16" (click)="routeClick(menuItem.title)" [routerLink]="!menuItem.type ? null : [menuItem.path]" (click)="isCollapsed = !isCollapsed">{{menuItem.title}}</a>
           </li>
           <li class="nav-item me-1" *ngIf="menuItem.type=='hyperlink'" style="text-align: left;">
             <a class="nav-link text-black  f-16" (click)="routeClick(menuItem.title)" [href]="[menuItem.path]" target="_blank">{{menuItem.title}}</a>
           </li>
           <li class="nav-item dropdown" *ngIf="menuItem.type=='sub'">
             <a href="javascript:void(0)" class="nav-link dropdown-toggle text-black f-16" (click)="toggleDropdown($event)" role="button" aria-haspopup="true" [attr.aria-expanded]="isDropdownOpen ? 'true' : 'false'" 
             style="background-color: unset; width: 100%; padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);">{{menuItem.title}}</a>
             <ul class="dropdown-menu" [ngClass]="{'show': isDropdownOpen}" style="width: 100%;">
               <li *ngFor="let childrenItem of menuItem.children">
                 <a class="dropdown-item text-black f-16" [routerLink]="!childrenItem.type ? null : [childrenItem.path]" routerLinkActive="router-link-active" (click)="toggleDropdown($event)" (click)="isCollapsed = !isCollapsed">{{childrenItem.title}}</a>
               </li>
             </ul>
           </li>
         </ng-container>
         <li class="nav-item" style="    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);">
          <button class="btn btn-primary btn-pill btn-lg d-flex align-items-center" (click)="openConnectWallet(content)" *ngIf="wallet ==null">
            <i class="icofont icofont-wallet f-26 me-1"></i> 
            <span class="f-14 f-w-500">Connect Wallet </span>
          </button>
         </li>
       </ul>
     </div>
   </div>
 </div>
  <app-livebar class="header-sub" id="header-stat"></app-livebar>
</div>

<!-- <div style="    position: sticky;
top: 80px;
z-index: 3;">
  <div id="collapseExample" [ngbCollapse]="!isCollapsed">
    <div class="card mb-0" style="border-radius: unset !important;">
      <div class="card-body" id="myTopnav">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Active</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" aria-disabled="true">Disabled</a>
          </li>
        </ul>
        <button class="btn btn-primary btn-pill btn-lg d-flex align-items-center m-t-20" (click)="openConnectWallet(content)" *ngIf="wallet ==null">
          <i class="icofont icofont-wallet f-26 me-1"></i> 
          <span class="f-14 f-w-500">Connect Wallet </span>
        </button>
      </div>
    </div>
  </div>
  <app-livebar class="header-sub" id="header-stat"></app-livebar>
</div> -->
<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <app-wallet-list></app-wallet-list>
  </div>
</ng-template>

<div class="gdpr container-fluid" *ngIf="show_gdpr">
  <div class="container">
    <h2>Welcome to Xode!</h2>
    <p>
      We use cookies to allow you to visit our website and use its functions. Some cookies are strictly 
      necessary for the operation of our website and cannot be deactivated. 
      Other cookies help us to improve your experience with our website by providing us with 
      statistics or enabling us to remember your preferences. You can allow the use of all cookies 
      or just essential cookies by clicking one of the below. You can change your preference at any 
      time in the Cookie Settings and learn more about cookies and how we use them by clicking here:
      <a href="#">Cookie Policy</a>
    </p>
    <div class="d-flex flex-column flex-md-row justify-content-end gap-2 align-items-center">
      <a href="#" class="action-btn secondary" (click)="handleGDPR()">Only allow essential cookies</a>
      <a href="#" class="action-btn primary" (click)="handleGDPR()">Allow all cookies</a>
    </div>
  </div>
</div>
