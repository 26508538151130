import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { AppSettings } from '../../app-settings';

@Injectable({
  providedIn: 'root'
})
export class BlockScannerService {
  constructor(
    private httpClient: HttpClient,
    private appSettings: AppSettings,
  ) {
    //
  }

  //
  public defaultAPIURLHost: string = this.appSettings.APIURLHost;
  homepageData: any;

  getHomepageData() {
    return this.homepageData;
  }

  loadBlockScannerData(): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.get<any>(
        this.defaultAPIURLHost + '/xode/homepage',
      ).subscribe({
        next: (response) => {
          let data = response;
          observer.next([true, data]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error]);
          observer.complete();
        }
      });
    });
  }
}
