import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../../app-settings';
import { CookiesService } from './cookies.service';
import Swal from 'sweetalert2';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class SmartContractService {
  constructor(
    private appSettings: AppSettings,
    private httpClient: HttpClient,
    private cookiesService: CookiesService,
  ) {
    //
  }
  public defaultAPIURLHost: string = this.appSettings.APIURLHost;
  contractData = [];

  storeContractData(data: any) {
    let actingAddress = this.cookiesService.getCookieArray("wallet-info").address;
    let existing_cookie = this.cookiesService.getCookieArray(actingAddress);
    if (existing_cookie == null) {
      this.contractData.push(data);
    } else {
      this.contractData = existing_cookie;
      this.contractData.push(data);
    }
    if (typeof data === 'object') {
      this.cookiesService.setCookieArray(actingAddress, this.contractData);
    } else {
      this.cookiesService.setCookieArray(actingAddress, this.contractData);
    }
    this.contractData = existing_cookie;
  }

  getContractData() {
    let actingAddress = this.cookiesService.getCookieArray("wallet-info").address;
    const data = this.cookiesService.getCookieArray(actingAddress);
    return data == null ? '' : data;
  }

  closeSwal() {
    Swal.close();
  }

  progressSwal(data: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    })
    Toast.fire({
      text: data,
    })
  }

  async fireSwal(
    success: boolean,
    swalTitle: string,
    swalText: string
  ) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    })
    Toast.fire({
      icon: success ? 'success' : 'error',
      title: swalTitle,
      text: swalText
    })
  };

  createErc20(data: any): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post<any>(
        this.defaultAPIURLHost + '/xode/smartcontract/erc20',
        { data },
        httpOptions
      ).subscribe({
        next: (response) => {
          let data = response;
          observer.next([true, data]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error]);
          observer.complete();
        }
      });
    });
  }

  getSmartContract(endpoint: string): Observable<[boolean, any]> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.get<any>(
        `${this.defaultAPIURLHost}/xode/smartcontract/${endpoint}`,
        httpOptions
      ).subscribe({
        next: (response) => {
          let data = response;
          observer.next([true, data]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error]);
          observer.complete();
        }
      });
    });
  }
}
