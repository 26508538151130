<div class="media profile-media">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.png" alt=""> -->
  <div style="padding: 6px 6px; display: flex;">
    <i class="icofont icofont-wallet icon-size-wallet"></i>
  </div>
  <div class="media-body" style="margin-left: 5px; color: white !important;">
    <span>{{wallet_info.metaName}}</span>
    <p class="mb-0 font-roboto">{{wallet_info.address_display}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>

<ul class="profile-dropdown onhover-show-div " *ngIf="isRendered" style="width: min-content;left: unset;">
  <li class="w-detail" style="display: flex; justify-content: space-between;">
    <span>{{'Balance' | translate}}:</span>
    <span> {{wallet_balance | number}} {{ tokenSymbol }}</span>
  </li>
  <li class="w-detail"  style="display: flex; justify-content: space-between;">
    <span>{{'Wallet' | translate}}:</span>
    <input #textInputField hidden type="text" [(ngModel)]="wallet_address">
    <span class="txt-white d-flex" style="gap: 0.5rem;"> {{wallet_info.address_display}} <a (click)="copyToClipboard()"><i class="fa fa-copy"></i></a></span>
  </li>
  <li class="w-detail" ><a href="javascript:void(0);" (click)="logout()">
    <table>
      <tr>
        <td><app-feather-icons style="size: 10px;" [icon]="'log-in'"></app-feather-icons></td>
        <td><span style="margin-bottom: 10px;">{{ 'Disconnect' | translate}}</span></td>
      </tr>
    </table>
  </a>
  </li>
    
</ul>

