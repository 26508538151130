import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT, ViewportScroller } from "@angular/common";

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { NavigationEnd, Router } from '@angular/router';
import { CookiesService } from "../../services/cookies.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NetworkModel } from "../../model/network.model";
import { environment } from 'src/environments/environment';
import { NetworkScannerModel } from "../../model/networkscanner.model";
import { PolkadotService } from "../../services/polkadot.service";
import { ApiPromise, WsProvider } from '@polkadot/api';
import { Menu, NavService } from "../../services-component/nav.service";
import { LayoutService } from "../../services-component/layout.service";

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public elem: any;

  // For Horizontal Menu
  public margin: any = 0;
  maxwith = 600;

  public wallet:any = this.cookiesService.getCookieArray("wallet-info");

  isRendered: boolean = false;

  public isCollapsed = false;

  public show_gdpr = true;

  public menuItems: Menu[];

  networks: NetworkModel[] = [];
  connected_network: NetworkModel;
  network_scanner: NetworkScannerModel = new NetworkScannerModel()
  wsProvider = new WsProvider(this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').wsProviderEndpoint  :environment.networks[0].network[0].wsProviderEndpoint);
  // api = ApiPromise.create({ provider: this.wsProvider });
  constructor(
    public layout: LayoutService, 
    public navServices: NavService, 
    private cookiesService: CookiesService,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private scroller: ViewportScroller,
    private modalService: NgbModal,
    private polkadotService: PolkadotService,
    ) {
      environment.networks.forEach(networkGroup => {
        networkGroup.network.forEach(network => {
          this.networks.push({
            id: network.id,
            name: network.name,
            wsProviderEndpoint: network.wsProviderEndpoint,
            net_name: network.net_name
          })
        });
      });
      if(this.cookiesService.getCookieArray('network')==null){
        this.cookiesService.setCookieArray('network',this.networks[0])
      }
      this.connected_network = this.cookiesService.getCookieArray('network')!=null? this.cookiesService.getCookieArray('network') : this.networks[1];

      this.navServices.items.subscribe(menuItems => {
        this.menuItems = menuItems;
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            menuItems.filter(items => {
              if (items.path === event.url) {
                this.setNavActive(items);
              }
              if (!items.children) { return false; }
              items.children.filter(subItems => {
                if (subItems.path === event.url) {
                  this.setNavActive(subItems);
                }
                if (!subItems.children) { return false; }
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url) {
                    this.setNavActive(subSubItems);
                  }
                });
              });
            });
          }
        });
      });
  }

  isDropdownOpen = false;
  currentDropdown: any = null;

  toggleDropdown(event: Event, menuItem: any) {
    event.preventDefault();
    
    // Close the currently open dropdown if it's not the clicked one
    if (this.currentDropdown === menuItem) {
      this.currentDropdown = null;
    } else {
      this.currentDropdown = menuItem;
    }
  }
  
  onSubItemClick(event: Event, menuItem: any) {
    event.stopPropagation(); // Prevent event from propagating up to parent dropdown
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    if ((this.layout.config.settings.layout_version = "dark-only")) {
      document.body.classList.toggle("dark-only");
    }
    document.body.remove;
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  openConnectWallet(content) {
    this.modalService.open(content, { centered: true });
  }

  public routeClick(section: string): void {
    setTimeout(() => {
        
      if (section === 'About') {
          window.scroll(0, 0);
      }
      else{
        const element = document.getElementById(section);
        if (element) {
          let y = element.getBoundingClientRect().top + window.scrollY - 150;
          if (window.innerWidth <= this.maxwith) {
            y = element.getBoundingClientRect().top + window.scrollY - 90;
          }
          window.scrollTo({
              top: y,
              behavior: 'smooth'
          });
        }
      }
    }, 100);
  }

  selectedNetwork(network: any){
    this.connected_network = network;
    this.cookiesService.setCookieArray('network',network);
    window.location.reload();
  }

  ngOnInit() {
    this.elem = document.documentElement;
    this.polkadotService.getTotalBlocks().subscribe((data)=>{
      if(data.net_name!=undefined){
        this.network_scanner = data;
      }
    })
    setTimeout(()=>{
      this.isRendered = true;
    },100)

    if (localStorage.getItem('no_gdpr_dialog')) {
      this.show_gdpr = false
    }
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  handleGDPR(e) {
    this.show_gdpr = false
    localStorage.setItem('no_gdpr_dialog', '1');
    e.preventDefault();
  }
  myFunction(){
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
    this.isCollapsed = !this.isCollapsed;
  }
}
